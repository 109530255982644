import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { m, useInView } from "framer-motion";
import { useMeasure } from "react-use";

import { useCarouselTimer } from "./hooks/useCarouselTimer";
import useSiftedMediaQueries from "lib/hooks/useSiftedMediaQueries";
import { PaginationDots } from "./paginationDots";
import { PrevNextButtons } from "./prevNextButtons";

function repeatArrayItems(arr: MultiSlideType[]) {
  const repeatedArr: MultiSlideType[] = [];

  while (repeatedArr.length < 5) {
    repeatedArr.push(...arr);
  }

  return repeatedArr;
}

export type MultiSlideType = {
  component: React.ReactNode;
  widths: number[];
  onClick?: Function;
};

type MultiSlideCarouselProps = {
  slides: MultiSlideType[];
  belowSlides?: "prevNext" | "prevNextWithCount" | "dots" | "none";
  responsiveGap?: number[];
  autoplay?: boolean;
  containerClasses?: string;
  slideClasses?: string;
  mainWrapperClasses?: string;
  timerMS?: number;
  onlyCenterInteractive?: boolean;
  paginationContainerClassesOverride?: string;
  autoHeight?: boolean;
  scaleCenter?: boolean;
  center?: "card" | "gap";
};

/**
 * Accepts an array of objects with a component and an array of card widths -
 * In the order of [desktop, tablet, mobile] if there are 3 entries,
 * [desktopAndTablet, mobile] if there are 2 entries, and [desktopTabletMobile]
 * if only 1 entry
 *
 * @param center - if `card` is chosen the card will be centered, and the maximum
 * number of cards on screen should be 3. If `gap` is chosen, the gap will be in
 * the middle and the maximum cards that can be shown is 4.
 */
export const MultiSlideCarousel = ({
  slides,
  belowSlides = "dots",
  responsiveGap = [20],
  autoplay = true,
  containerClasses,
  mainWrapperClasses,
  paginationContainerClassesOverride,
  slideClasses,
  timerMS = 5000,
  onlyCenterInteractive = false,
  autoHeight = false,
  scaleCenter = true,
  center = "card",
}: MultiSlideCarouselProps) => {
  const ref = useRef(null);
  const wasDraggedRef = useRef(false);
  const isInView = useInView(ref);
  /**
   * TODO: Fix that to calculate the biggest between visible, not the centered one
   * TODO: Add tests
   */
  const [sliderContainerRef, { height: sliderContainerHeight }] =
    useMeasure<HTMLDivElement>();

  const { mobile, tablet, desktop } = useSiftedMediaQueries();
  const [isHydrated, setIsHydrated] = useState(false);

  // Wait until after client-side hydration to show
  useEffect(() => {
    setIsHydrated(true);
  }, []);

  const entries = slides.length < 5 ? repeatArrayItems(slides) : slides;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [interactionCount, setInteractionCount] = useState(0);

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + entries.length) % entries.length
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % entries.length);
  };

  const handlePrevSlide = () => {
    setInteractionCount(interactionCount + 1);
    prevSlide();
  };
  const handleNextSlide = () => {
    setInteractionCount(interactionCount + 1);
    nextSlide();
  };

  useCarouselTimer(
    autoplay,
    nextSlide,
    interactionCount,
    setInteractionCount,
    timerMS,
    isInView
  );

  const isCenterGap = center === "gap";

  const getIndicesToShow = () => {
    const prevIndex3 = (currentIndex - 3 + entries.length) % entries.length;
    const prevIndex2 = (currentIndex - 2 + entries.length) % entries.length;
    const prevIndex = (currentIndex - 1 + entries.length) % entries.length;
    const nextIndex = (currentIndex + 1) % entries.length;
    const nextIndex2 = (currentIndex + 2) % entries.length;

    const indiciesArray = [
      prevIndex2,
      prevIndex,
      currentIndex,
      nextIndex,
      nextIndex2,
    ];

    if (isCenterGap) {
      indiciesArray.unshift(prevIndex3);
    }

    return indiciesArray;
  };

  const indicesToShow = getIndicesToShow();

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  function getResponsiveVal(entry: any[]) {
    if (entry.length === 2) {
      if (mobile) return entry[1];
      return entry[0];
    }
    if (entry.length === 3) {
      if (mobile) return entry[2];
      if (tablet) return entry[1];
      if (desktop) return entry[0];
      return entry[0];
    }
    return entry[0];
  }

  const gap = getResponsiveVal(responsiveGap);

  return (
    <div
      data-testid={"carousel-main-wrapper"}
      className={classNames("w-100", mainWrapperClasses)}
      ref={ref}
    >
      {isHydrated && (
        <>
          <div
            data-testid={"carousel-container"}
            style={{
              height: autoHeight ? sliderContainerHeight : undefined,
            }}
            className={classNames(
              containerClasses,
              "relative w-100 overflow-hidden"
            )}
          >
            {indicesToShow.map((index) => {
              const width = getResponsiveVal(entries[index].widths);
              const prevWidth = getResponsiveVal(
                entries[(currentIndex - 1 + entries.length) % entries.length]
                  .widths
              );
              const prevPrevWidth = getResponsiveVal(
                entries[(currentIndex - 2 + entries.length) % entries.length]
                  .widths
              );
              const centralWidth = getResponsiveVal(
                entries[currentIndex].widths
              );
              const nextWidth = getResponsiveVal(
                entries[(currentIndex + 1) % entries.length].widths
              );

              const slide = {
                leftWhenSlideZero:
                  currentIndex === 0 && index === entries.length - 1,
                rightWhenLastSlide:
                  currentIndex === entries.length - 1 && index === 0,

                leftOfLeftOfLeft:
                  index ===
                  (currentIndex - 3 + entries.length) % entries.length,
                leftOfLeft:
                  index ===
                  (currentIndex - 2 + entries.length) % entries.length,
                left: index === currentIndex - 1,
                center: index === currentIndex,
                right: index === currentIndex + 1,
                rightOfRight: index === (currentIndex + 2) % entries.length,
              };

              const getX = () => {
                const offset = isCenterGap ? gap / 2 : (centralWidth / 2) * -1;

                // make last slide loop
                if (slide.leftWhenSlideZero)
                  return `calc(${offset}px - ${width + gap}px)`;
                // make first slide loop
                if (slide.rightWhenLastSlide)
                  return `calc(${offset}px + ${centralWidth + gap}px)`;

                if (isCenterGap && slide.leftOfLeftOfLeft)
                  return `calc(${gap / 2}px - ${
                    width + gap + prevWidth + gap + prevPrevWidth + gap
                  }px)`;
                if (slide.leftOfLeft)
                  return `calc(${offset}px - ${
                    width + gap + prevWidth + gap
                  }px)`;
                if (slide.left) return `calc(${offset}px - ${width + gap}px)`;
                if (slide.center) return `${offset}px`;
                if (slide.right)
                  return `calc(${offset}px + ${centralWidth + gap}px)`;
                if (slide.rightOfRight)
                  return `calc(${offset}px + ${
                    centralWidth + gap + nextWidth + gap
                  }px)`;
              };

              const getZIndex = () => {
                if (onlyCenterInteractive) {
                  if (slide.center) return 1;
                  return 0;
                } else {
                  return 1;
                }
              };

              const getPointerEvents = () => {
                if (onlyCenterInteractive)
                  return currentIndex === index ? "auto" : "none";

                return "auto";
              };

              const getOpacity = () => {
                if (isCenterGap) {
                  if (slide.rightOfRight) return 0;
                  return 1;
                }
                if (slide.leftWhenSlideZero || slide.rightWhenLastSlide)
                  return 1;
                if (slide.leftOfLeft || slide.rightOfRight) return 0;
                return 1;
              };

              return (
                <m.div
                  className={classNames(
                    slideClasses,
                    "absolute left-1/2 top-0 overflow-hidden"
                  )}
                  key={index}
                  data-testid={`multi-slide-${index || 0}`}
                  style={{
                    width: getResponsiveVal(entries[index].widths),
                    pointerEvents: getPointerEvents(),
                  }}
                  initial={{ x: getX() }}
                  animate={{
                    x: getX(),
                    zIndex: getZIndex(),
                    opacity: getOpacity(),
                    scale:
                      !isCenterGap && scaleCenter && currentIndex === index
                        ? 1
                        : 0.975,
                  }}
                  transition={{
                    duration: 0.5,
                    x: { type: "spring", stiffness: 330, damping: 29 },
                  }}
                  whileDrag={{ x: getX() }}
                  drag="x"
                  dragConstraints={{
                    left: -centralWidth / 2,
                    right: -centralWidth / 2,
                  }}
                  dragElastic={false}
                  onClick={() => {
                    if (!wasDraggedRef.current && entries[index].onClick)
                      entries[index].onClick?.();
                  }}
                  onDragStart={() => (wasDraggedRef.current = true)}
                  onDragEnd={(_, { offset, velocity }) => {
                    wasDraggedRef.current = false;
                    const swipe = swipePower(offset.x, velocity.x);
                    if (swipe < -swipeConfidenceThreshold) {
                      handleNextSlide();
                    } else if (swipe > swipeConfidenceThreshold) {
                      handlePrevSlide();
                    }
                  }}
                >
                  <div
                    ref={
                      index === currentIndex ? sliderContainerRef : undefined
                    }
                  >
                    {entries[index].component}
                  </div>
                </m.div>
              );
            })}
          </div>
          {belowSlides === "dots" ? (
            <PaginationDots
              slides={slides}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              interactionCount={interactionCount}
              setInteractionCount={setInteractionCount}
              type="multi"
            />
          ) : belowSlides === "prevNext" ? (
            <PrevNextButtons
              paginationContainerClassesOverride={
                paginationContainerClassesOverride
              }
              handlePrevSlide={handlePrevSlide}
              handleNextSlide={handleNextSlide}
            />
          ) : belowSlides === "prevNextWithCount" ? (
            <PrevNextButtons
              paginationContainerClassesOverride={
                paginationContainerClassesOverride
              }
              handlePrevSlide={handlePrevSlide}
              handleNextSlide={handleNextSlide}
              currentTotalCount={
                <span className="flex items-center px-8 text-[14px]">
                  <span className="font-bold text-black-rock">{`${
                    currentIndex + 1
                  }`}</span>
                  <span className="text-mono-40">{`/${slides.length}`}</span>
                </span>
              }
            />
          ) : null}
        </>
      )}
    </div>
  );
};

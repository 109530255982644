import { wrap } from "./lib";
import type { SingleSlideType } from "./singleSlide";
import type { MultiSlideType } from "./multiSlide";

type PaginationDotsProps = {
  slides: SingleSlideType[] | MultiSlideType[]; // TODO
  currentIndex: number;
  setCurrentIndex: (arg0: any) => void;
  interactionCount: number;
  setInteractionCount: (arg0: number) => void;
  type: "single" | "multi";
};

export const PaginationDots = ({
  slides,
  currentIndex,
  setCurrentIndex,
  interactionCount,
  setInteractionCount,
  type,
}: PaginationDotsProps) => {
  if (slides.length < 2) return null;
  return (
    <nav
      className="mt-[3px] flex justify-center"
      aria-label="Carousel pagination"
    >
      <div className="flex justify-center rounded-full p-1">
        {slides.map((_, i) => {
          const imageIndex = wrap(0, slides.length, currentIndex);
          return (
            <button
              key={i}
              aria-label={`Slide ${i + 1}`}
              disabled={i === currentIndex}
              onClick={() => {
                setInteractionCount(interactionCount + 1);
                if (type === "multi") {
                  setCurrentIndex(i);
                } else {
                  if (i > currentIndex) setCurrentIndex([i, -1]);
                  if (i < currentIndex) setCurrentIndex([i, 1]);
                }
              }}
              style={{
                background: i === imageIndex ? "#020231" : "#C5C5C5",
              }}
              className="mx-[2.5px] h-[6px] w-[6px] cursor-pointer rounded-full bg-white"
            ></button>
          );
        })}
      </div>
    </nav>
  );
};

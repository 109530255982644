import classNames from "classnames";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { MouseEventHandler, ReactElement } from "react";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";
import IconButton from "components/iconButton/iconButton";

type PaginateButtonProps = {
  action: MouseEventHandler<HTMLButtonElement | undefined>;
  icon: ReactElement | string;
  classes?: string;
};

const PaginateButton = ({ action, icon }: PaginateButtonProps) => {
  return (
    <IconButton variant={"rounded"} className={"mx-[10px]"} onClick={action}>
      {icon}
    </IconButton>
  );
};

type PrevNextButtonsProps = {
  handlePrevSlide: MouseEventHandler<HTMLButtonElement | undefined>;
  handleNextSlide: MouseEventHandler<HTMLButtonElement | undefined>;
  paginationContainerClassesOverride?: string;
  currentTotalCount?: React.ReactNode | null;
};

export const PrevNextButtons = ({
  handlePrevSlide,
  handleNextSlide,
  paginationContainerClassesOverride,
  currentTotalCount = null,
}: PrevNextButtonsProps) => {
  const { track } = useMixpanelTracking();
  return (
    <div
      data-testid={"prev-next-buttons-container"}
      className={classNames(
        paginationContainerClassesOverride || "mt-[20px] flex justify-center"
      )}
    >
      <PaginateButton
        action={(e) => {
          track("Click previous slide", {});
          handlePrevSlide(e);
        }}
        icon={<MdChevronLeft size={"1.4em"} />}
      />
      {currentTotalCount}
      <PaginateButton
        action={(e) => {
          track("Click next slide", {});
          handleNextSlide(e);
        }}
        icon={<MdChevronRight size={"1.4em"} />}
      />
    </div>
  );
};

import { useEffect, useRef } from "react";

export const useCarouselTimer = (
  autoplay: boolean,
  nextSlide: Function,
  interactionCount: number,
  setInteractionCount: (arg0: number) => void,
  timerMS: number,
  isInView: boolean
) => {
  const intervalIdRef: { current: ReturnType<typeof setInterval> | null } =
    useRef(null);
  const timeoutIdRef: { current: ReturnType<typeof setTimeout> | null } =
    useRef(null);

  const clearTimers = () => {
    if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
  };

  useEffect(() => {
    if (!autoplay || !isInView) return clearTimers;

    const resetTimer = () => {
      clearTimers();

      timeoutIdRef.current = setTimeout(() => {
        nextSlide();
        setInteractionCount(0);
      }, timerMS);
    };

    const startTimer = () => {
      intervalIdRef.current = setInterval(() => {
        if (interactionCount === 0) {
          nextSlide();
        } else {
          resetTimer();
        }
      }, timerMS);
    };

    startTimer();

    return clearTimers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactionCount, autoplay, isInView]);
};
